import { render, staticRenderFns } from "./slideout.vue?vue&type=template&id=055b1356&scoped=true"
import script from "./slideout.vue?vue&type=script&lang=ts"
export * from "./slideout.vue?vue&type=script&lang=ts"
import style0 from "./slideout.vue?vue&type=style&index=0&id=055b1356&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "055b1356",
  null
  
)

export default component.exports